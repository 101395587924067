import React from "react";
import LogoData from "./logodata";
import ScrollerComponent from "./ScrollerComponent";
import Benefits from "./benefits";
import { Link } from "react-router-dom";

const Private = () => {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-col mt-28 items-center justify-center px-4 py-8 md:flex-row md:justify-between md:py-16">
        <div className="ml-20 md:order-1 md:w-1/2 md:pr-8">
          <h2 className="text-blue-grey-900 mb-4 text-3xl font-bold md:text-4xl">
          We Provide Best and Very Secure Cloud Services

          </h2>
          <p className="mb-6 text-base md:text-lg text-gray-500">

            Unlock the Potential of Your Casting Agency with CIMS

            Welcome to the future of casting management! At Casting Industry Management System (CIMS), we understand the unique challenges faced by casting agencies and production companies. That's why we've developed a state-of-the-art software solution tailored to streamline your workflow, enhance efficiency, and drive success in the dynamic world of entertainment.
          </p>

          <button className="rounded-full bg-gradient-to-r from-blue-600 to-blue-900 px-5 py-4 text-lg font-bold text-white shadow-md transition duration-300 hover:bg-red-700 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
            Contact Now +7028828831
          </button>
        </div>
        <div className="md:order-2 md:w-1/2">
          <img
            src="/image/privateCloude/slider.webp"
            alt="Image"
            className="mx-auto w-full md:max-w-lg"
          />
        </div>
      </div>


      {/* Hero Section */}
      <section className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-4xl lg:text-6xl font-bold text-gray-800 mb-6">Revolutionize Your Private Cloude</h1>
        <p className="text-lg text-gray-600 mb-8">Streamline auditions, manage talent, and elevate your casting agency with CIMS.</p>
        <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-full">Learn More</button>
      </section>

      {/* Features Section */}
      <section className="bg-gray-200 py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">Why Choose Cloude Service?</h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="p-8 bg-white rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Effortless Casting Management</h3>
              <p className="text-gray-700">Simplify the entire casting process with our intuitive platform. Manage auditions, callbacks, and bookings in one centralized location.</p>
            </div>
            <div className="p-8 bg-white rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Comprehensive Talent Database</h3>
              <p className="text-gray-700">Access a vast database of talented actors, models, and performers. Easily search, filter, and categorize talents based on specific criteria.</p>
            </div>
            <div className="p-8 bg-white rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Real-time Collaboration</h3>
              <p className="text-gray-700">Collaborate seamlessly with talent, agents, and production teams through our integrated messaging system.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}

      {/* <QuestionData /> */}

      <section class="bg-gray-50 dark:bg-gray-800">
        <div class="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          <div class="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div class="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">Unlock the Potential of Your Cloude Server with 5techG</h2>
              <p class="mb-8 font-light lg:text-xl">Deliver great service experiences fast - without the complexity of traditional CIMS solutions. Accelerate critical development work, eliminate toil, and deploy changes with ease.</p>
              <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Advanced Analytics and Reporting:</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Secure and Reliable</span>
                </li>
                <li class="flex space-x-3">
                  <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Flexible and Scalable</span>
                </li>
              </ul>
              <p class="mb-8 font-light lg:text-xl">Deliver great service experiences fast - without the complexity of traditional ITSM solutions.</p>
            </div>
            <img class="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="/image/privateCloude/about.webp" alt="dashboard feature image" />
          </div>
          <div class="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
  <img class="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="/image/privateCloude/b2.webp" alt="casting management software" />
  <div class="text-gray-500 sm:text-lg dark:text-gray-400">
    <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">Effortless Cloude Server</h2>
    <p class="mb-8 font-light lg:text-xl">Simplify your casting process with our intuitive platform. Manage auditions, callbacks, and bookings seamlessly.</p>
    <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
      <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
        <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Centralized Talent Hub</span>
      </li>
      <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
        <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Efficient Audition Management</span>
      </li>
      <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
        <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Customizable Casting Calls</span>
      </li>
      <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
        <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Real-time Collaboration</span>
      </li>
      <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
        <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Advanced Analytics and Reporting</span>
      </li>
      <li class="flex space-x-3">
        <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
        <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Secure and Reliable</span>
      </li>
    </ul>
    <p class="font-light lg:text-xl">Join the ranks of leading casting agencies and production houses that have already embraced the power of CIMS.</p>
  </div>
</div>

        </div>
      </section>
      <section class="bg-white dark:bg-gray-900">
        <div class="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
          <div class="col-span-2 mb-8">
            <p class="text-lg font-medium text-purple-600 dark:text-purple-500">Trusted Worldwide</p>
            <h2 class="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white">Trusted by over 50+ users and 100+ teams</h2>
            <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">Our rigorous security and compliance standards are at the heart of all we do. We work tirelessly to protect you and your customers.</p>

          </div>
          <div class="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              <svg class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z" clip-rule="evenodd"></path></svg>
              <h3 class="mb-2 text-2xl font-bold dark:text-white">99.99% uptime</h3>
              <p class="font-light text-gray-500 dark:text-gray-400">For Landwind, with zero maintenance downtime</p>
            </div>
            <div>
              <svg class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path></svg>
              <h3 class="mb-2 text-2xl font-bold dark:text-white">50+ Users</h3>
              <p class="font-light text-gray-500 dark:text-gray-400">Trusted by over 600 milion users around the world</p>
            </div>
            <div>
              <svg class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd"></path></svg>
              <h3 class="mb-2 text-2xl font-bold dark:text-white">10+ States</h3>
              <p class="font-light text-gray-500 dark:text-gray-400">Have used Landwind to create functional websites</p>
            </div>
            <div>
              <svg class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>
              <h3 class="mb-2 text-2xl font-bold dark:text-white">60+</h3>
              <p class="font-light text-gray-500 dark:text-gray-400">Try per day</p>
            </div>
          </div>
        </div>
      </section>
      {/*  */}


      <section className="bg-blue-500 py-20 text-center">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl lg:text-4xl font-bold text-white mb-6">Ready to Elevate Your Cloude Service</h2>
          <p className="text-lg text-white mb-8">Schedule a demo today and see how CIMS can transform your casting process!</p>
          <Link to='/contact'>  <button className="bg-white hover:bg-gray-200 text-blue-500 font-semibold py-3 px-8 rounded-full">Schedule Demo</button></Link>
        </div>
      </section>
    </div>
  );
};

export default Private;
