import React from 'react';

const WhatsAppIcon = () => {
  return (
    <div className="fixed bottom-10 right-10">
      <a href="https://wa.me/7028828831" target="_blank" rel="noopener noreferrer">
        <img src="/image/whatsapp/Animation - 1710935618039.gif" alt="WhatsApp Icon" className="w-12 h-16" />
      </a>
    </div>
  );
};

export default WhatsAppIcon;
