import React from "react";
import SectionHeader from "../Common/SectionHeader";

const Team = () => {
    // Array of team members with their details
    const teamMembers = [
        {
            name: "Rushikesh Koli",
            profession: "CEO & Founder",
            imageSrc: "/image/teams/dummy.png"
        },

        {
            name: "Sambhu Raj ",
            profession: "Co-Founder",
            imageSrc: "/image/teams/dummy.png"
        },
        {
            name: "Rohan Patil",
            profession: "Marketing Head",
            imageSrc: "/image/teams/dummy.png"
        },
        {
            name: "Satish Vishwkarma",
            profession: "Full Stack Developer",
            imageSrc: "/image/teams/dummy.png"
        },
        {
            name: "Jatin Chauhan",
            profession: "Full Stack Developer",
            imageSrc: "/image/teams/dummy.png"
        },
        {
            name: "Ajay Namdev",
            profession: "UI/UX Head",
            imageSrc: "/image/teams/dummy.png"
        }
    ];

    return (
        <div className="pb-10 pt-20 border   lg:pb-20 lg:pt-[60px]">
            <div className=" container  md:grid md:grid-cols-5 mx-auto">

                {/* <div className="animate_top mx-auto col-span-2 text-center">
                    <SectionHeader
                        headerInfo={{
                            title: `Team`,
                            subtitle: ` Our Awesome Team`,
                            description: `Our software company boasts a team of 40+ skilled developers and designers who are passionate about creating innovative solutions for our clients. There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form.`,
                        }}
                    />
                </div> */}
                <div className="-mx-4 flex md:col-span-2 flex-wrap">
                    <div className="w-full px-4">
                        <div className="mx-auto mb-[60px] max-w-[510px] text-">
                            <span className="mb-6 block text-lg font-semibold text-primary">
                                Our Awesome Team
                            </span>
                            {/* <h2 className="mb-6 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                                We have 40+ Team of Talented Developers and Designers
                            </h2> */}
                            <h1 className="mb-5 pr-16 text-xl font-bold text-black  xl:text-4xl ">
                            We have Team of 40+  <span className="text-[#DE4396]"> </span> {"   "}
                                <span className="relative inline-block before:absolute before:bottom-2.5 pt-2 before:left-0 before:-z-1 before:h-3 before:w-full   ">
                                Talented Developers and Designers
                                </span>
                            </h1>
                            <p className="text-base text-body-color dark:text-dark-6">
                                Our software company boasts a team of 40 skilled developers and designers who are passionate about creating innovative solutions for our clients.
                            </p>
                            <p className="text-base text-body-color mt-10 dark:text-dark-6">
                                "As the CEO of  company, I am incredibly proud of the talented team of developers and designers we have assembled. Together, we strive to deliver the highest quality solutions to our clients and push the boundaries of innovation in the technology industry. Thank you for choosing us to bring your ideas to life"
                            </p>
                            <p className="text-end  font-bold mt-4">Mr. Rushikesh koli</p>
                            <p className="text-end  font-medium text-meta ">CEO & Founder</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap md:col-span-3  justify-center">
                    {teamMembers.map((member, index) => (
                        <TeamCard
                            key={index}
                            name={member.name}
                            profession={member.profession}
                            imageSrc={member.imageSrc}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Team;

const TeamCard = ({ imageSrc, name, profession }) => {
    return (
        <div className="w-full px-10 md:w-1/2 xl:w-1/3">
            <div className="mx-auto mb-10 w-full max-w-[370px]">
                <div className="relative overflow-hidden rounded-lg">
                    <img src={imageSrc} alt="" className="w-full" />
                    <div className="absolute bottom-5 left-0 w-full text-center">
                        <div className="relative mx-8 overflow-hidden rounded-lg bg-white px-3 py-3 ">
                            <h3 className="text-base font-semibold text-dark ">
                                {name}
                            </h3>
                            <p className="text-xs text-body-color ">
                                {profession}
                            </p>
                            <div>
                                <span className="absolute bottom-0 left-0">
                                    <svg
                                        width={61}
                                        height={30}
                                        viewBox="0 0 61 30"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx={16}
                                            cy={45}
                                            r={45}
                                            fill="#13C296"
                                            fillOpacity="0.11"
                                        />
                                    </svg>
                                </span>
                                <span className="absolute right-0 top-0">
                                    <svg
                                        width={20}
                                        height={25}
                                        viewBox="0 0 20 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="0.706257"
                                            cy="24.3533"
                                            r="0.646687"
                                            transform="rotate(-90 0.706257 24.3533)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="6.39669"
                                            cy="24.3533"
                                            r="0.646687"
                                            transform="rotate(-90 6.39669 24.3533)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="12.0881"
                                            cy="24.3533"
                                            r="0.646687"
                                            transform="rotate(-90 12.0881 24.3533)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="17.7785"
                                            cy="24.3533"
                                            r="0.646687"
                                            transform="rotate(-90 17.7785 24.3533)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="0.706257"
                                            cy="18.6624"
                                            r="0.646687"
                                            transform="rotate(-90 0.706257 18.6624)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="6.39669"
                                            cy="18.6624"
                                            r="0.646687"
                                            transform="rotate(-90 6.39669 18.6624)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="12.0881"
                                            cy="18.6624"
                                            r="0.646687"
                                            transform="rotate(-90 12.0881 18.6624)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="17.7785"
                                            cy="18.6624"
                                            r="0.646687"
                                            transform="rotate(-90 17.7785 18.6624)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="0.706257"
                                            cy="12.9717"
                                            r="0.646687"
                                            transform="rotate(-90 0.706257 12.9717)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="6.39669"
                                            cy="12.9717"
                                            r="0.646687"
                                            transform="rotate(-90 6.39669 12.9717)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="12.0881"
                                            cy="12.9717"
                                            r="0.646687"
                                            transform="rotate(-90 12.0881 12.9717)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="17.7785"
                                            cy="12.9717"
                                            r="0.646687"
                                            transform="rotate(-90 17.7785 12.9717)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="0.706257"
                                            cy="7.28077"
                                            r="0.646687"
                                            transform="rotate(-90 0.706257 7.28077)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="6.39669"
                                            cy="7.28077"
                                            r="0.646687"
                                            transform="rotate(-90 6.39669 7.28077)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="12.0881"
                                            cy="7.28077"
                                            r="0.646687"
                                            transform="rotate(-90 12.0881 7.28077)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="17.7785"
                                            cy="7.28077"
                                            r="0.646687"
                                            transform="rotate(-90 17.7785 7.28077)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="0.706257"
                                            cy="1.58989"
                                            r="0.646687"
                                            transform="rotate(-90 0.706257 1.58989)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="6.39669"
                                            cy="1.58989"
                                            r="0.646687"
                                            transform="rotate(-90 6.39669 1.58989)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="12.0881"
                                            cy="1.58989"
                                            r="0.646687"
                                            transform="rotate(-90 12.0881 1.58989)"
                                            fill="#3056D3"
                                        />
                                        <circle
                                            cx="17.7785"
                                            cy="1.58989"
                                            r="0.646687"
                                            transform="rotate(-90 17.7785 1.58989)"
                                            fill="#3056D3"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// export default TeamCard;
