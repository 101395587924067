const LogoData = [
  {
    image: "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/happy_customer.webp",
    alt: "Logo 1",
    title: "1 Crore+",
    subtitle: "Happy customers"
  },
  {
    image: "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/phone_icon.webp",
    alt: "Logo 2",
    title: "FREE",
    subtitle: "Android Mobile App"
  },
  {
    image: "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/gplay_store.webp",
    alt: "Logo 3",
    title: "Rated 4.7/5",
    subtitle: "on Google Play Store"
  },
  {
    image: "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/multi_device.webp",
    alt: "Logo 4",
    title: "Multi-Device",
    subtitle: "Use together on Mobile/Desktop"
  },
  {
    image: "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/multi_user.webp",
    alt: "Logo 5",
    title: "Multi-User",
    subtitle: "User Management Feature"
  },
  // Add more logo objects as needed
];

export default LogoData;