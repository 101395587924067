// featuresData.tsx
const benefitsData = [
  {
    imageUrl:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/Benefits/lifetime_free_basic_usage.webp",
    title: "Needs Assessment",
    description:
      "Conduct a thorough analysis of business requirements and challenges related to distributor management.",
  },
  {
    imageUrl:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/Benefits/track_your_business_status.webp",
    title: "Solution Selection",
    description:
      "Research and evaluate Distributor Management System (DMS) options to find the best fit for your business needs and budget.",
  },
  {
    imageUrl:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/Benefits/manage_cashflow_seamlessly.webp",
    title: "Customization and Configuration",
    description:
      "Tailor the chosen DMS to align with specific business processes and integrate with existing systems.",
  },
  {
    imageUrl:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/Benefits/provide_multiple_payment_options.webp",
    title: "Training and Onboarding",
    description:
      "Provide comprehensive training to employees and distributors on using the DMS effectively to ensure smooth adoption.",
  },
  {
    imageUrl:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/Benefits/keep_data_safe_with_backups.webp",
    title: "Pilot Testing:",
    description:
      "Conduct a pilot implementation of the DMS with a small group of users to identify and address any issues before full deployment. ",
  },
  {
    imageUrl:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/Benefits/build_a_positive_brand_image.webp",
    title: "Rollout and Support",
    description:
      "Deploy the DMS across the organization, providing ongoing support and assistance to ensure successful implementation and user satisfaction.",
  },

  // Add more objects for additional features
];

export default benefitsData;
