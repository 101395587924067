import React, { useState } from "react";
import ScrollerComponentData from "./ScrollerComponentData";

const ScrollerComponent = () => {
  const [selectedStep, setSelectedStep] = useState("Improved Efficiency");

  const handleStepClick = (step) => {
    setSelectedStep(step);
  };

  return (
    <section className="container mx-auto px-4 md:px-8 lg:px-16 xl:px-20">
      <div className="benefits-header-div ">
        <h2 className="mb-10 mt-20 text-center text-3xl font-bold md:text-4xl">
          Benefits of Using DMS
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="max-h-96 overflow-y-scroll ">
          {ScrollerComponentData.slice(0, 12).map((item, index) => (
            <div
              key={index}
              className={`my-4 flex cursor-pointer items-center rounded-md border p-4 text-lg md:text-2xl ${
                selectedStep === item.step ? "bg-gray-200" : ""
              }`}
              onClick={() => handleStepClick(item.step)}
            >
              <img
                src={item.image}
                alt={`Step ${item.step}`}
                className="mr-4 h-auto w-12 md:h-auto md:w-16"
              />
              <span>{item.step}</span>
            </div>
          ))}
        </div>
        <div className="p-8">
          <h2 className="text-lg font-bold md:text-2xl">
            {ScrollerComponentData.find((item) => item.step === selectedStep)
              ?.title || ` ${selectedStep}`}
          </h2>
          <p className="mt-4">
            {
              ScrollerComponentData.find((item) => item.step === selectedStep)
                ?.title
            }
          </p>
          <p className="mt-2 text-gray-500">
            {
              ScrollerComponentData.find((item) => item.step === selectedStep)
                ?.subtext
            }
          </p>
        </div>
      </div>
    </section>
  );
};

export default ScrollerComponent;
