import React from 'react'

function Brands() {
  return (
    <div>
      <section class="bg-gray-100  ">
        <div class="py-8 lg:py-10 mx-auto max-w-screen-xl px-4">
          <div class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-14 md:grid-cols-3 lg:grid-cols-6 ">
         
            <a href="#" class="flex justify-center items-center">
              <img className='h-18 opacity-80 hover:opacity-100' src='/image/brandnew/logoBrother.png'></img>

            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-18 opacity-80 hover:opacity-100' src='/image/brandnew/logo-jivalla.png'></img>

            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/gmart.png'></img>
            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/ramasala.png'></img>
            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/DBagro.svg'></img>
            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-20 opacity-80 hover:opacity-100' src='/image/brandnew/effo.png'></img>
            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/deepzi.svg'></img>

            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/logoTultech.svg'></img>

            </a>

            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/matrutithLogo.svg'></img>

            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/docsDuniya.svg'></img>

            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/manmandir11.svg'></img>

            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100 ' src='/image/brandnew/sanveeLogo.svg'></img>
            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/Styanidhi.svg'></img>
            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-16 opacity-80 hover:opacity-100' src='/image/brandnew/pickupLogos.png'></img>
            </a>
          
            <a href="#" class="flex justify-center items-center">
              <img className='h-16 opacity-80 hover:opacity-100' src='/image/brandnew/Sparkle-Logo.png'></img>

            </a>

            <a href="#" class="flex justify-center items-center">
              <img className='h-14 opacity-80 hover:opacity-100' src='/image/brandnew/brand1.png'></img>

            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-18 opacity-80 hover:opacity-100' src='/image/brandnew/brand2.png'></img>

            </a>
            <a href="#" class="flex justify-center items-center">
              <img className='h-18 opacity-80 hover:opacity-100' src='/image/brandnew/brand3.png'></img>

            </a>
          </div>
        </div>
       
      </section>

    </div>
  )
}

export default Brands
