const featuresTabData = [
  {
    id: "tabOne",
    title: "Distributors Management System",
    desc1: `A Distributors Management System is a comprehensive solution for managing the distribution of goods and services. It automates key processes such as order processing, inventory management, and delivery tracking.`,
    desc2: `With a Distributors Management System, businesses can optimize their supply chain, reduce operational costs, and improve customer satisfaction.`,
    image: "/image/featuresnew/distributer1.png",
    imageDark: "/image/featuresnew/distributer1.png",
    buttonUrl: "/distruter"
  },
  {
    id: "tabTwo",
    title: "Casting Industry Management Software",
    desc1: `Casting Industry Management Software is designed to streamline the management of casting processes in industrial settings. It provides tools for tracking inventory, managing production schedules, and optimizing workflow.`,
    desc2: `This software helps companies in the casting industry to improve efficiency, reduce costs, and enhance overall productivity.`,
    image: "/image/featuresnew/casting1.png",
    imageDark: "/image/featuresnew/casting1.png",
    buttonUrl: "/casting"
  },
 
  {
    id: "tabThree",
    title: "Metaverse Development",
    desc1: `he Metaverse is basically a vision of the people of the computer sector who believe it's the next version of the internet - a shared, permanent, single, and 3D virtual place where human beings may experience life in various ways they cannot in the real world.`,
    desc2: `By integrating smart devices and sensors, homeowners can enhance convenience, comfort, and energy efficiency in their homes.`,
    image: "https://binmile.com/wp-content/uploads/2022/06/metaverse-to-change-the-future-of-work-all-you-need-to-know.jpg",
    imageDark: "/image/featuresnew/smarthome.jpg",
  },
  {
    id: "tabFour",
    title: "private cloude server",
    desc1: `private cloude server  is a technology that enables users to access and use computing resources (such as servers, storage, and databases) over the internet, on-demand.`,
    desc2: `This flexible and scalable computing model allows organizations to reduce infrastructure costs, improve agility, and scale resources according to their needs.`,
    image: "/image/featuresnew/cloud.jpg",
    imageDark: "/image/featuresnew/cloud.jpg",
    buttonUrl:"/private"
  },
];

export default featuresTabData;
