import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Hero from "../Hero/Hero";
import AboutCompany from "../AboutCompany";
import Contact from "../Contact";
import DistributionManagement from "../DistributorManagement";
import Casting from "../Casting/Casting";
import WhatsAppIcon from "../whatsapp/WhatsApp";
import Portfolios from "../Portfolios";
import Private from "../privateCloud/Private";
import Porduct from "../Product";

const Page = () => {
  return (
    <HashRouter>
      <Navbar />
      <WhatsAppIcon/>
      {/* <Header/> */}
      <Routes>
        <Route exact path="/" element={<Hero />} />
        <Route  path="/About" element={<AboutCompany />} />
        <Route  path="/portfolios" element={<Portfolios />} />
        <Route  path="/contact" element={<Contact />} />
        <Route  path="/distruter" element={<DistributionManagement />} />
        <Route  path="/casting" element={< Casting/>} />
        <Route  path="/private" element={< Private/>} />
        <Route  path="/product" element={< Porduct/>} />
        
      </Routes>
      <Footer />
    </HashRouter>
  );
};

export default Page;
