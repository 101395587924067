
const featuresData = [
  {
    id: 1,
    icon: "/image/iconnew/mobile.png",
    title: "Mobile App Development",
    description:
      "Our team specializes in creating exceptional mobile apps that captivate users. From innovative design to seamless functionality, trust us to deliver remarkable mobile solutions that drive success.",
  },
  {
    id: 2,
    icon: "/image/iconnew/web-development.png",
    title: "Web App Development",
    description:
     "Our expertise lies in creating standout websites that leave a lasting impression. From captivating design to seamless functionality, trust us to elevate your online presence and drive digital success."
  },
  {
    id: 3,
    icon: "/image/iconnew/icon-3.png",
    title: "Cloud Computing services",
    description: "We provide end-to-end cloud computing services, including private cloud setup, management, and expertise in Google Cloud, AWS, and Azure for public cloud deployment."
  },
  {
    id: 4,
    icon: "/image/iconnew/icon-5.png",
    title: "Data Science services",
    description: "Our data science services leverage advanced analytics and machine learning techniques to extract valuable insights from your data. From data preprocessing to predictive modeling, we help businesses harness the power of data to make informed decisions and drive growth"
  },
  {
    id: 5,
    icon: "/image/iconnew/robot.png",
    title: "Robotics Process Automation",
    description: "Our robotic process automation (RPA) services streamline repetitive tasks, freeing up valuable human resources. With our expertise in RPA implementation and optimization, we help businesses enhance productivity, reduce errors, and achieve greater operational efficiency."
  },
  {
    id: 6,
    icon: "/image/iconnew/icon-4.png",
    title: "Digital Marketing",
    description:
      "Our IoT development services empower businesses to harness the potential of the Internet of Things. From device connectivity to data analytics, we create innovative IoT solutions that drive efficiency and enable smart, connected experiences.",
  },
];

export default featuresData;
