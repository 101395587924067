import React from "react";
import benefitsData from "./benefitsData";

const Benefits = () => {
  return (
    <section className=" mx-10 mt-20 pb-16 pt-8">
      <div className="">
        <h2 className="text-center text-3xl font-bold md:text-4xl">Implementation Process</h2>
      </div>
      <div className="mb-10 mt-20 grid grid-cols-1 items-center gap-8 md:grid-cols-3">
        <div className="col-span-1 md:col-span-1">
          <div className="grid grid-cols-1 gap-4">
            {benefitsData.slice(0, 3).map((feature, index) => (
              <div key={index} className="flex flex-col gap-4">
                <div>
                  <img
                    src={feature.imageUrl}
                    alt="Benefit"
                    className="h-16 w-16 md:h-auto md:w-auto"
                  />
                </div>
                <div>
                  <h2 className="benefit-item text-lg md:text-xl">{feature.title}</h2>
                  <p className="read-more">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-1 mt-20 text-center md:col-span-1">
          <img
            src="/image/DistributionManagement/Group 108 (1).png"
            alt="Benefits"
            className="mx-auto h-auto max-w-full"
          />
        </div>
        <div className="col-span-1 md:col-span-1">
          <div className="grid grid-cols-1 gap-4">
            {benefitsData.slice(3, 6).map((feature, index) => (
              <div key={index} className="flex flex-col gap-4">
                <div>
                  <img
                    src={feature.imageUrl}
                    alt="Benefit"
                    className="h-16 w-16 md:h-auto md:w-auto"
                  />
                </div>
                <div>
                  <h3 className="benefit-item text-lg md:text-xl">{feature.title}</h3>
                  <p className="read-more">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
