import React from "react";
import { Link } from "react-router-dom";

const FeaturesTabItem = ({ featureTab }) => {
  const { title, desc1, desc2, image, buttonUrl } = featureTab;

  return (
    <div className="md:grid md:grid-cols-2 ">
      {/* Image */}


      {/* Text */}
      <div className="col-span-1  lg:px-10">
        <h3 className="text-4xl font-bold text-black ">{title}</h3>
        <p className="mt-3 text-md text-gray-600 ">{desc1}</p>
        <p className="mt-3 text-md  text-gray-600 ">{desc2}</p>
        <Link  to={buttonUrl}>
        <a  href={buttonUrl} class="inline-flex items-center mt-8 w-full px-5 py-3 mb-3 mr-1 text-base font-semibold text-white no-underline align-middle bg-blue-600 border border-transparent border-solid rounded-md cursor-pointer select-none sm:mb-0 sm:w-auto hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700">
          Read More
          <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
        </a>
        </Link>
      </div>
      <div className="col-span-1 xs:pt-10">
        <img
          src={image}
          alt={title}
          className=" w-[600px] h-[400px] mx-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default FeaturesTabItem;
