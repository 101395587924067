import './App.css';
import Pages from "./Components/Pages/Page";

function App() {
  return (
    <div>
   
        <Pages />

    </div>
  );
}

export default App;
