import React from 'react';

const Footer = () => {
  return (
    <>
      <footer className="border-t border-stroke bg-white ">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-20">
          {/* Footer Top */}
          <div className="py-20 lg:pt-16">
            <div className="flex flex-wrap gap-8 lg:justify-between lg:gap-0">
              <div className="w-1/2 lg:w-1/4">
                <a href="/" className="relative">
                  <img
                    width={110}
                    height={80}
                    src="/image/logo/logo.png"
                    alt="Logo"
                  />
                 
                </a>
                <p className="mb-10 mt-5">
                  Bringing your brand to life with breathtaking web and mobile experiences
                </p>
                <p className="mb-2 font-bold text-sectiontitle uppercase tracking-[5px]">
                  contact
                </p>
                <a
                  href="mailto:contact@5techg.com"
                  className="text-itemtitle font-medium text-black "
                >
                  contact@5techg.com
                </a>
              </div>
              <div className="flex w-full flex-col gap-8 md:flex-row md:justify-between md:gap-0 lg:w-2/3 xl:w-7/12">
                <div className="animate_top">
                  <h4 className="mb-9 text-xl text-itemtitle2 font-medium text-black ">
                    Services
                  </h4>
                  <ul>
                    <li>
                      <a href="#" className="mb-3 inline-block hover:text-primary">
                        Website Development
                      </a>
                    </li>
                    <li>
                      <a href="#" className="mb-3 inline-block hover:text-primary">
                        Mobile App Development
                      </a>
                    </li>
                    <li>
                      <a href="#" className="mb-3 inline-block hover:text-primary">
                        Search Engine Optimization
                      </a>
                    </li>
                    <li>
                      <a href="#" className="mb-3 inline-block hover:text-primary">
                        Social Media Marketing
                      </a>
                    </li>
                    <li>
                      <a href="#" className="mb-3 inline-block hover:text-primary">
                        Logo & Branding
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="animate_top">
                  <h4 className="mb-9 text-itemtitle2 text-xl font-medium text-black ">
                    Quick Link
                  </h4>
                  <ul>
                    <li>
                      <a href="#" className="mb-3 inline-block hover:text-primary">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#" className="mb-3 inline-block hover:text-primary">
                        About
                      </a>
                    </li>
                    <li>
                      <a href="#" className="mb-3 inline-block hover:text-primary">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="animate_top">
                  <h4 className="mb-9 text-itemtitle2 text-xl font-medium text-black ">
                    Newsletter
                  </h4>
                  <p className="mb-4 w-[90%]">
                    Subscribe to receive future updates
                  </p>
                  <form action="#">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Email address"
                        className="w-full rounded-full border border-stroke px-6 py-3 shadow-solid-11 focus:border-primary focus:outline-none "
                      />
                      <button aria-label="signup to newsletter" className="absolute right-0 p-4">
                        <svg
                          className="fill-[#757693] hover:fill-primary "
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_48_1487)">
                            <path
                              d="M3.1175 1.17318L18.5025 9.63484C18.5678 9.67081 18.6223 9.72365 18.6602 9.78786C18.6982 9.85206 18.7182 9.92527 18.7182 9.99984C18.7182 10.0744 18.6982 10.1476 18.6602 10.2118C18.6223 10.276 18.5678 10.3289 18.5025 10.3648L3.1175 18.8265C3.05406 18.8614 2.98262 18.8792 2.91023 18.8781C2.83783 18.8769 2.76698 18.857 2.70465 18.8201C2.64232 18.7833 2.59066 18.7308 2.55478 18.6679C2.51889 18.6051 2.50001 18.5339 2.5 18.4615V1.53818C2.50001 1.46577 2.51889 1.39462 2.55478 1.33174C2.59066 1.26885 2.64232 1.2164 2.70465 1.17956C2.76698 1.14272 2.83783 1.12275 2.91023 1.12163C2.98262 1.12051 3.05406 1.13828 3.1175 1.17318ZM4.16667 10.8332V16.3473L15.7083 9.99984L4.16667 3.65234V9.16651H8.33333V10.8332H4.16667Z"
                              fill=""
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_48_1487">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Top */}
          {/* Footer Bottom */}
          <div className="flex flex-col flex-wrap items-center justify-center gap-5 border-t border-stroke py-7  lg:flex-row lg:justify-between lg:gap-0">
            <div className="animate_top">
              <ul className="flex items-center gap-8">
                <li>
                  <a href="#" className="hover:text-primary">
                    English
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-primary">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-primary">
                    Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="animate_top">
              <p>&copy; {new Date().getFullYear()} 5TechG. All rights reserved</p>
            </div>
            <div className="animate_top">
              <ul className="flex items-center gap-5">
                <li>
                  <a href="#" aria-label="social icon">
                    <svg
                      className="fill-[#D1D8E0] transition-all duration-300 hover:fill-primary"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* Insert your SVG path here */}
                    </svg>
                  </a>
                </li>
                {/* Add more social icons here */}
              </ul>
            </div>
          </div>
          {/* Footer Bottom */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
