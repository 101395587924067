import React from "react";
import { motion } from "framer-motion";

function Card({ item, index }) {
    return (
        <motion.div
            className="card"
            initial={{
                opacity: 0,
                x: index % 2 === 0 ? 50 : -50
            }}
            whileInView={{
                opacity: 1,
                x: 0, 
                transition: {
                    duration: 1 
                }
            }}
            viewport={{ once: true }}
        >
            <div key={item._id} class="  bg-white  shadow ">
                <a href="#">
                    <img className=" w-full h-80" src={item.mainImage} alt="" />
                </a>
                <div class="pl-8 pb-8 pt-8">
                    <a href="#">
                        <h5 class=" hover:text-blue-400 text-2xl font-bold tracking-tight text-gray-900 ">{item.title}</h5>
                    </a>
                </div>
            </div>
        </motion.div>
    );
}

export default Card;
