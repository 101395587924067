import React from 'react';

import CompanyJourney from "./journey";
import Team from './Team';
import TeamCard from './Team';


const AboutCompany = () => {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return (
    <>
      {/* <section className="text-center">
     <img src="/images/teams/Group-3.svg" className="w-[100%]" style={{ marginTop: '0%' }} ></img>
      </section> */}

      <section className="overflow-hidden pb-20  mt-40 xl:pb-25 md:mt-28">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
          <div className="flex lg:items-center lg:gap-8 xl:gap-32.5">
            <div className=" md:w-1/2">
              <h4 className="mb-4.5 text-lg font-medium text-rose " >
                Our information
              </h4>
              <h1 className="mb-5 pr-16 text-3xl font-bold text-black  xl:text-hero ">Introduction</h1>
              <h4 className="mb-4.5 text-lg font-medium text-black ">
                We are a team of experienced professionals with thorough expertise in marketing, developing and designing.
                We build brands success digitally through our exceptional IT solutions like web development, mobile app development,
                full-stack web development, UI/UX development, etc. Our expertise extends to developing software, games, mobile applications,
                and much more in all their forms that you can think of. striving to minimize our carbon footprint and contribute positively to our communities.
              </h4>


            </div>

            <div className=" hidden lg:block">
              <div className="">
                {/* <img src="https://5techg.com/assets/media/banner/about-office.svg" alt="Apple"> */}
                <img src="https://5techg.com/assets/media/banner/about-office.svg" className="w-[800px] "></img>

              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <Team/>
      </div>

      <div>
        <div className=" text-center mb-10">
          <div className="" >
            <h1 className="mb-5 pt-6 text-3xl font-bold text-black  xl:text-hero">Our Journey Timeline</h1>
            <p>consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
        </div>

        <CompanyJourney />
      </div>
      
    </>
  );
};


export default AboutCompany;
