import React from "react";
import LogoData from "./logodata";
import ScrollerComponent from "./ScrollerComponent";
import Benefits from "./benefits";

const DistributionManagement = () => {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-col mt-28 items-center justify-center px-4 py-8 md:flex-row md:justify-between md:py-16">
        <div className="ml-20 md:order-1 md:w-1/2 md:pr-8">
          <h2 className="text-blue-grey-900 mb-4 text-3xl font-bold md:text-4xl">
            A Distributor Management System (DMS)
          </h2>
          <p className="mb-6 text-base md:text-lg text-gray-500">
            a crucial tool for companies that rely on distributor networks to sell their products.
            It streamlines the process of managing relationships with distributors, tracking sales,
            inventory, commissions, and more.
          </p>

          <button className="rounded-full bg-gradient-to-r from-red-600 to-red-900 px-5 py-4 text-lg font-bold text-white shadow-md transition duration-300 hover:bg-red-700 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
            Contact Now +7028828831
          </button>
        </div>
        <div className="md:order-2 md:w-1/2">
          <img
            src="/image/DistributionManagement/Group 110.png"
            alt="Image"
            className="mx-auto w-full md:max-w-lg"
          />
        </div>
      </div>

      {/* Additional section for logo list */}
      <div className="mt-8 flex justify-center">
        <div className="grid grid-cols-5 gap-4">
          {LogoData.map((logo, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="flex h-20 w-20 items-center justify-center">
                <img
                  src={logo.image}
                  alt={logo.alt}
                  className="max-h-full max-w-full"
                />
              </div>
              <h3 className="mt-2 text-lg font-semibold">
                {logo.title.split("").map((char, charIndex) => (
                  <span
                    key={charIndex}
                    className={
                      char === "+" ||
                        char === "5" ||
                        char === "D" ||
                        char === "U"
                        ? "text-red-500"
                        : ""
                    }
                  >
                    {char}
                  </span>
                ))}
              </h3>
              <p className="text-sm text-gray-600">{logo.subtitle}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-20 flex flex-col items-center justify-center px-4 py-8 md:flex-row md:justify-between md:py-16">
        <div className="md:order-1 md:w-1/2">
          <img
            src="/image/DistributionManagement/Group 110.png"
            alt="Image"
            className="mx-auto w-full md:max-w-md"
          />
        </div>
        <div className="mx-20  md:order-2 md:w-1/2">
          <h2 className="text-cyanblue-900 mb-4 text-3xl font-bold md:text-4xl">
            Sales Tracking
          </h2>

          <p className="mb-6 text-base md:text-lg text-gray-500">
            Track distributor sales activities and performance instantly with our Sales Tracking system. Gain real-time insights into sales trends, customer interactions, and revenue generation. Optimize strategies, enhance productivity, and make informed decisions to drive business growth. Stay ahead of the competition with actionable data at your fingertips.
            .
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-4 py-8 md:flex-row md:justify-between md:py-16">
        <div className="mx-20  md:order-1 md:w-1/2 ">
          <h2 className="text-blue-grey-900 mb-4 text-3xl font-bold md:text-4xl">
            Inventory Management
          </h2>
          <p className="mb-6 text-base md:text-lg text-gray-500">
            Efficiently monitor and manage inventory levels across multiple locations with our Inventory Management system. Streamline operations, minimize stockouts, and optimize inventory turnover. Gain insights into stock movement, track product availability, and ensure timely replenishment. Enhance efficiency and reduce costs with comprehensive inventory tracking and management capabilities.
          </p>
        </div>
        <div className="md:order-2 md:w-1/2">
          <img
            src="https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/check_inventory_instantly.webp?v=0.1"
            alt="Image"
            className="mx-auto w-full md:max-w-lg"
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-4 py-8 md:flex-row md:justify-between md:py-16">
        <div className="mx-20  md:order-2 md:w-1/2 ">
          <h2 className="text-blue-grey-900 mb-4 text-3xl font-bold md:text-4xl">
            Commission Management
          </h2>
          <p className="mb-6 text-base md:text-lg text-gray-500">
            Automate commission calculations and payments seamlessly with our Commission Management system. Tailor commission structures to match your sales performance metrics and effortlessly generate accurate commission reports. Simplify the process, eliminate errors, and ensure fair compensation for your sales team. Focus on growth while we handle commission management efficiently.
          </p>
        </div>
        <div className="md:order-1 md:w-1/2">
          <img
            src="/image/DistributionManagement/shop.png"
            alt="Image"
            className="mx-auto w-full md:max-w-lg"
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-4 py-8 md:flex-row md:justify-between md:py-16">
        <div className="mx-20  md:order-1 md:w-1/2">
          <h2 className="text-blue-grey-900 mb-4 text-3xl font-bold md:text-4xl">
            Order Management
          </h2>
          <p className="mb-6 text-base md:text-lg text-gray-500">

            Empower your distributors with streamlined online order placement and seamless order tracking through our Order Management system. Enhance efficiency, reduce errors, and improve customer satisfaction by providing real-time visibility into order status. Simplify the ordering process and streamline operations for enhanced productivity and customer service excellence.
          </p>
        </div>
        <div className="md:order-2 md:w-1/2">
          <img
            src="https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/gst_filing_made_simplerAndFaster.webp"
            alt="Image"
            className="mx-auto w-full md:max-w-lg"
          />
        </div>
      </div>

      <ScrollerComponent />
      <Benefits />
      {/* <QuestionData /> */}
    </div>
  );
};

export default DistributionManagement;
