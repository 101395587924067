import React from 'react'
import Card from './Cardmain';
import SectionHeader from '../../Common/SectionHeader';

function Cards() {

    const BlogData = [
        {
            _id: 1,
            mainImage: "/image/blognew/brother1.png",
            title: "Brothers Indrusties",

        },
        {
            _id: 2,
            mainImage: "/image/blognew/sanvee1.png",
            title: "Sanvee Flavours",

        },
        {
            _id: 3,
            mainImage: "/image/blognew/ljtex.png",

            title: "LJTex SB Exports",

        },
        {
            _id: 4,
            mainImage: "/image/blognew/satyanidhi.png",
            title: "Shri Satya Nidhi Bank",

        },
        {
            _id: 5,
            mainImage: "/image/blognew/sparkle.png",
            title: "sparkle Carier",

        },
        {
            _id: 6,
            mainImage: "/image/blognew/manmandir.png",
            title: "Manmandir Bank",

        },


    ];



    return (
        <>
            <div className="animate_top mx-auto text-center">
                <SectionHeader
                    headerInfo={{
                        title: `Works`,
                        subtitle: `Some of Our Fine Work`,
                        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In convallis tortor eros. Donec vitae tortor lacus. Phasellus aliquam ante in maximus.`,
                    }}
                />
            </div>
            <div className="grid grid-cols-1 mx-20 mt-36  sm:grid-cols-2 md:grid-cols-3  gap-20">
             

                {
                    BlogData.map((tab) => (

                        <Card index={tab._id} item={tab} />

                    ))
                }
            </div>
        </>
    )
}

export default Cards
