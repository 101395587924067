import React from "react";
import featuresData from "./featuresDatas";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="max-w-md cursor-pointer shadow-solid-3 transition-all hover:shadow-solid-4 bg-white shadow-lg rounded-lg overflow-hidden mx-auto py-10 px-6 mb-10">
      <img className="w-15 h-15 object-cover object-center" src={icon} alt={title} />
      <div className="p-4">
        <h2 className="text-2xl font-bold font-sans text-gray-900">{title}</h2>
        <p className="mt-2 text-gray-600 font-inter leading-normal ">{description}</p>
      </div>
    </div>
  );
};

const FeatureCards = () => {
  return (
    <div className="grid pb-20 lg:mx-20 lg:py-25 xl:py-30 grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
      
      {featuresData.map((feature) => (
        <FeatureCard key={feature.id} {...feature} />
      ))}
    </div>
  );
};

export default FeatureCards;
