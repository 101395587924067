
// featuresData.tsx
const ScrollerComponentData = [
  {
    step: "Improved Efficiency",
    image:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/features/Send Estimate.webp?v=0.1",
    title: "Improved Efficiency",
    subtext:
      "Streamline operations and reduce manual efforts in managing distributor relationships, sales, and inventory.",
  },

  {
    step: "Enhanced Visibility",
    image:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/features/Track order.webp?v=0.1",
    title: "Enhanced Visibility",
    subtext:
      "Gain real-time visibility into sales activities, inventory levels, and distributor performance.",
  },
  {
    step: "Better Decision Making",
    image:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/features/Choose theme.webp?v=0.1",
    title: "Better Decision Making",
    subtext:
      "Make informed decisions based on data-driven insights and analytics provided by the DMS.",
  },
  {
    step: "Scalability",
    image:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/features/Record expenses.webp?v=0.1",
    title: "Scalability",
    subtext:
      "Easily scale your distributor network and operations as your business grows with the flexibility of the DMS.",
  },
  {
    step: "Increased Sales",
    image:
      "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/home-page-revamp/features/Receivable and payable.webp?v=0.1",
    title: "Increased Sales",
    subtext:
      "Optimize sales processes, track leads effectively, and provide better support to distributors, leading to increased sales and revenue.",
  },

  // Add more steps as needed
];

export default ScrollerComponentData;
