// productData.js
const productData = [
    {
      image: "/image/Product/mdDashboard.svg" ,
      title: "Clinic Management software",
      content: "Reinvent your clinic and enhance your patient care with 5tech-G. From scheduling appointments to patients registrations, check-in, consultations, prescriptions, billing and reporting. the comprehensive 5tech-G platform enables you to deliver a seamless customer experience to your patients. Here's an opportunity to scale up your business."
    },
    {
      image: "/image/Product/MarketPro.svg",
      title: "Market Pro Mobile App",
      content: "Send your business ad via WhatsApp and SMS Automatically on every new call - all incoming, outgoing, missed, dial calls. Can add photos, videos, or PDFs. It is not necessary to save the number for WhatsApp. You can change the ad whenever you want  "
    },
    {
      image: "/image/Product/dist_sys.png",
      title: "Distribution System",
      content: "An advanced solution for optimizing goods and services distribution, streamlining supply chain processes, reducing costs, and improving efficiency for businesses through automation and real-time visibility."
    },
    {
      image: "/image/Product/smartFact.png",
      title: "Smart Factory software",
      content: "Smart factory software is a computer program that leverages technologies like IoT, AI, and data analytics to automate and optimize manufacturing processes in a factory. It collects real-time data, enables machine-to-machine communication, predicts maintenance needs, automates workflows. "
    },
    {
      image: "/image/Product/erp_1.png",
      title: "ERP system for casting company",
      content: "Streamline casting operations with an efficient ERP system managing inventory, production planning, resource allocation, and quality control."
    },
    {
      image: "/image/Product/imrs.png",
      title: "Matrimonial app",
      content: "A user-friendly mobile application connecting individuals for matrimonial purposes, facilitating profile creation, partner search, and communication for a seamless matchmaking experience."
    },
    {
      image: "/image/Product/e-commerce.png",
      title: "E-Commerce App",
      content: "A robust online platform enabling businesses to showcase and sell their products or services, providing a convenient shopping experience for customers with secure transactions and efficient order management."
    },
    {
      image: "/image/Product/learning.png",
      title: "Learning Management System",
      content: "Efficient platform for creating, delivering, and managing educational courses and training."
    },
    {
      image: "/image/Product/food.png",
      title: "Food delivery system",
      content: "A convenient platform connecting restaurants, customers, and delivery partners, allowing users to order food online and have it delivered to their doorstep efficiently."
    },
    {
      image: "/image/Product/referal.png",
      title: "Referal System",
      content: "A mechanism that incentivizes individuals to refer new customers or users to a business or platform, rewarding them with benefits or discounts for successful referrals. It encourages word-of-mouth marketing and helps expand the customer base organically."
    },
   
  ];
  
  export default productData;
  