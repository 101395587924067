import React from 'react';

const TestimonialsSection = () => {
    return (
        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="flex flex-col items-center">
                    <div className="text-center">
                        <h2 className="mt-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">Our happy clients say about us</h2>
                    </div>

                    {/* <div className="mt-8 text-center md:mt-16 md:order-3">
                        <a href="#" title="" className="pb-2 text-base font-bold leading-7 text-gray-900 transition-all duration-200 border-b-2 border-gray-900 hover:border-gray-600 font-pj focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-gray-600"> Check all 2,157 reviews </a>
                    </div> */}

                    <div className="relative mt-10 md:mt-24 md:order-2">
                        <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                            <div className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter" style={{ background: 'linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)' }}></div>
                        </div>

                        <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3">
                            <div className="flex flex-col overflow-hidden shadow-xl">
                                <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                                    <div className="flex-1">
                                        <div className="flex items-center">
                                        </div>
                                        <blockquote className="flex-1 mt-8">
                                            <p className="text-md leading-relaxed text-gray-600 hover:text-gray-900 font-pj">"5TechG delivered exceptional solutions that revolutionized our operations. Their attention to detail, commitment to quality, and ability to understand our unique requirements set them apart. We're grateful for their contribution to our success and would recommend them to anyone in need of reliable IT services"</p>
                                        </blockquote>
                                    </div>
                                    <div className="flex items-center mt-8">
                                    <img className="flex-shrink-0 object-cover rounded-full h-11 " src="/image/testimonial/testimonialuser.jpg" alt="" />
                                        <div className="ml-4">
                                            <p className="text-base font-bold text-gray-900 font-pj">Mr.Deepak chavan</p>
                                            <p className="mt-0.5 text-sm font-pj text-gray-600">Rdm and Deepzi tea owner</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col overflow-hidden shadow-xl">
                                <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                                    <div className="flex-1">
                                        <div className="flex items-center">
                                        </div>
                                        <blockquote className="flex-1 mt-8">
                                            <p className="text-md leading-relaxed text-gray-600 hover:text-gray-900 font-pj">Working with 5techG has been a game-changer for our business. Their software development expertise helped us bring our ideas to life, and the end result exceeded our expectations. The team at 5TechG is dedicated, skilled, and innovative. We look forward to continuing our partnership with them in the future."</p>
                                        </blockquote>
                                    </div>
                                    <div className="flex items-center mt-8">
                                    <img className="flex-shrink-0 object-cover rounded-full h-11 " src="/image/testimonial/testimonialuser.jpg" alt="" />
                                        <div className="ml-4">
                                            <p className="text-base font-bold text-gray-900 font-pj">Shailesh Deshmukh</p>
                                            <p className="mt-0.5 text-sm font-pj text-gray-600">dbagrofoods owner</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col overflow-hidden shadow-xl">
                                <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                                    <div className="flex-1">
                                        <div className="flex items-center">
                                        </div>
                                        <blockquote className="flex-1 mt-8">
                                            <p className="text-md leading-relaxed text-gray-600 hover:text-gray-900 font-pj">"Our experience with 5TechG has been outstanding. They provided us with top-notch software solutions that streamlined our operations and improved our efficiency. The team was highly professional, responsive, and knowledgeable. We highly recommend 5TechG to any organization seeking reliable IT services."</p>
                                        </blockquote>
                                    </div>
                                    <div className="flex items-center mt-8">
                                        <img className="flex-shrink-0 object-cover rounded-full h-11 " src="/image/testimonial/testimonialuser.jpg" alt="" />
                                        <div className="ml-4">
                                            <p className="text-base font-bold text-gray-900 font-pj">Chetan suryawanshi</p>
                                            <p className="mt-0.5 text-sm font-pj text-gray-600">Brothers industries</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialsSection;
