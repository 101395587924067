
import React, { useState } from "react";
import Brands from "./brands/Brands";
import FeatureCards from "./service/FeatureCards";
import FeaturesTab from "./FeaturesTab/FeaturesTab";
import Cards from "./Cards/Cards";
import Integration from "./Integration/Integration";
import About from "./About";
import Testimonial from "./testimonial/Testimonial";
import TestimonialsSection from "./testimonial/Testimonial";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";


const Hero = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const text = "Lets Build Together".split(" ");


  return (

    <div>

      <section className="overflow-hidden pb-20 xl:pb-25 mt-40">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
          <div className="flex lg:items-center lg:gap-8 xl:gap-32.5">
            <div className=" md:w-1/2">
              <h4 className="mb-4.5 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded text-md font-medium w-48  px-4.5 py-1 text-white ">
                <div className="App">
                  {text.map((el, i) => (
                    <motion.span
                      key={i}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{
                        duration: 1,
                        delay: i / 10,
                        repeat: 200,
                        repeatDelay: 3,
                      }}
                    >
                      {el}{" "}
                    </motion.span>
                  ))}

                </div>
              </h4>

              <h1 className="mb-5 pr-16 text-3xl font-bold text-black  xl:text-hero ">
                Great <span className="text-[#DE4396]">Products</span> {"   "}
                <span className="relative inline-block before:absolute before:bottom-2.5 pt-2 before:left-0 before:-z-1 before:h-3 before:w-full before:bg-titlebg  ">
                  is buit by Great Teams
                </span>
              </h1>
              <p>
                We help build and manage a team of world-class developers to bring your vision to life
              </p>

              <div className="mt-10">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-wrap gap-5">

                    <Link to="/contact">
                      {/* <button
                        aria-label="get started button"
                        className="flex rounded-full bg-black px-7.5 py-2.5 text-white duration-300 ease-in-out hover:bg-blackho "
                      >
                        Contact us
                      </button> */}
                      <button class="relative  inline-flex items-center justify-center font-bold overflow-hidden group rounded-full">
                        <span class="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                        <span class="relative px-6 py-3 transition-all ease-out bg-gray-900 rounded-md group-hover:bg-opacity-0 duration-400">
                          <span class="relative text-white">Contact Now</span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </form>

                <p className="mt-5 text-black ">
                  Bringing your brand to life with breathtaking web and mobile experiences.
                </p>
              </div>
            </div>

            <div className=" hidden lg:block">
              <div className="">
                <img src="/image/shape/mainBnner2.png" className="w-[940px] "></img>

              </div>
            </div>
          </div>
        </div>
      </section>
      <Brands />
      <div>
        <h2 class=" pt-16 lg:mb-14 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900  md:text-4xl">You’ll be in good company</h2>

        <FeatureCards />

      </div>
      <div>
        <FeaturesTab />
      </div>
      <div className="pt-40">
        <About />
      </div>
      <div className="pt-40">
        <Cards />
        {/* <Blog/> */}
      </div>
      <div className="pt-40">
        <Integration />
      </div>
      <div className="pt-40">
        <TestimonialsSection />
      </div>

    </div>
  );
};

export default Hero;
