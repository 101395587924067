import React, { useState } from 'react';
import productData from './productData'; // Import productData

const Babban = ({ image, title, content }) => {
  return (
    <div className="w-full md:w-1/3 sm:px-4 mb-8 md:mb-0 mt-20">
      <section className="overflow-hidden pb-6 xl:pb-10 rounded-lg bg-white flex flex-col justify-between border-2 border-gray-300">
        <div className="flex justify-center items-center h-64 mt-4">
          <img src={image} alt={title} className="max-w-full max-h-full" />
        </div>
        <div className="flex flex-col  justify-center p-6 ">
          <h3 className="text-lg md:text-xl font-bold mb-4 mt-10 text-gray-800 text-center">{title}</h3>
          <p className="text-sm md:text-base text-gray-600 text-left">{content}</p>
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-4 rounded-full transition duration-300 self-center">Learn More</button>
        </div>
      </section>
    </div>
  );
}

const Product = () => {
  const [showMore, setShowMore] = useState(false);

  const handleExploreMore = () => {
    setShowMore(!showMore);
  }

  return (
    <div className="container mx-auto p-4 mt-10">
      <div className="flex flex-wrap -mx-4">
        {productData.slice(0, 6).map((product, index) => (
          <Babban
            key={index}
            image={product.image}
            title={product.title}
            content={product.content}
          />
        ))}
      </div>
      {!showMore && (
        <div className="flex justify-center mt-8">
          <button onClick={handleExploreMore} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300">Explore More</button>
        </div>
      )}
      {showMore && (
        <div className="flex flex-wrap -mx-4 mt-8">
          {productData.slice(4, 12).map((product, index) => (
            <Babban
              key={index}
              image={product.image}
              title={product.title}
              content={product.content}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Product;
